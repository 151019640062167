import { styled } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';

const StyledMuiTab = styled(MuiTab)({
  color: '#999999',

  '&.Mui-selected': {
    color: '#5D43CF !important',
    opacity: 1,
  },
});

export default function Tab(props) {
  return <StyledMuiTab {...props} />;
}
