import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import IconButtonWithLabel from 'lib/IconButtonWithLabel';
import { success as successAlert } from 'state/notifications/actions';

import { muteCall } from '../state/actions';
import { selectCallMuted, selectTwilioConnection } from '../state/selectors';

import styles from './MuteCallButton.module.scss';

function MuteCallButton({ smallSize = false }) {
  const dispatch = useDispatch();

  const connection = useSelector(selectTwilioConnection);
  const callMuted = useSelector(selectCallMuted);

  const toggleMute = () => {
    connection.mute(!callMuted);
    dispatch(muteCall(!callMuted));
    dispatch(successAlert(callMuted ? 'Call unmuted.' : 'Call muted.'));
  };

  const showLabel = () => (callMuted ? 'Muted' : 'Mute');

  const toggleColor = () => (callMuted ? 'dark-purple' : 'purple');

  if (smallSize) {
    return (
      <IconButton
        onClick={toggleMute}
        className={`${styles['container-small']} ${callMuted ? styles['call-muted'] : ''}`}
      >
        <MicOffOutlinedIcon fontSize="small" />
      </IconButton>
    );
  }

  return (
    <div className={styles.container}>
      <IconButtonWithLabel
        icon={<MicOffOutlinedIcon />}
        classes={styles.button}
        color={toggleColor()}
        iconSize="large"
        handleOnClick={toggleMute}
        label={showLabel()}
      />
    </div>
  );
}

MuteCallButton.propTypes = {
  smallSize: PropTypes.bool,
};

export default MuteCallButton;
