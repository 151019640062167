import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { IconButton } from '@mui/material';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  selectMissedCallsCount,
  selectUnreadMessagesCount,
  selectUnreadVoicemailsCount,
} from 'app/state/counters/selectors';

import styles from './CallStatus.module.scss';

export default function CallStatus({ onClick, isInCall, isLoggingActivity }) {
  const unreadText = useSelector(selectUnreadMessagesCount);
  const missedCalls = useSelector(selectMissedCallsCount);
  const voicemails = useSelector(selectUnreadVoicemailsCount);
  const unreadNotificationsCount = unreadText + missedCalls + voicemails;

  return (
    <div
      className={cs({
        [styles['phone-wrapper']]: true,
        [styles['is-in-call']]: isInCall,
        [styles['is-logging-activity']]: isLoggingActivity,
      })}
    >
      <IconButton
        aria-label="Phone"
        onClick={onClick}
        data-cy="navbar-toggle-twilio-button"
        size="large"
      >
        <PhoneOutlinedIcon data-cy="navbar-phone-icon" />
      </IconButton>
      {isInCall ||
        isLoggingActivity ||
        (!!unreadNotificationsCount && (
          <div className={styles.indicator}>{unreadNotificationsCount}</div>
        ))}
    </div>
  );
}

CallStatus.defaultProps = {
  isInCall: false,
  isLoggingActivity: false,
};

CallStatus.propTypes = {
  onClick: PropTypes.func.isRequired,
  isInCall: PropTypes.bool,
  isLoggingActivity: PropTypes.bool,
};
