import { useState } from 'react';

import { removeArrayElementAtIndex } from 'helpers/utils';
import attachmentService from 'services/attachments';

const useFileUpload = (uploaderRef) => {
  const [files, setFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onUploadStart = (file, next) => {
    const totalFileSize = files.map((f) => f.file).reduce((a, b) => a + b.size, 0) / 1024;
    const supportedMimeTypes = ['image/jpeg', 'image/gif', 'image/png'];

    // abort upload if maximum number of files reached
    if (files.length === 10) {
      setError('You are not allowed to upload more than 10 files!');
      return;
    }
    // abort upload if unsupported mime type
    if (!supportedMimeTypes.includes(file.type)) {
      setError(`${file.type} file type is not supported!`);
      return;
    }
    // abort upload if total file size exceeds 5MB
    if (totalFileSize + file.size / 1024 > 5120) {
      setError('File size exceeds maximum size of 5MB');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      setUploading(true);
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          file,
          preview: e.target.result,
        },
      ]);
    };

    reader.readAsDataURL(file);

    next(file);
  };

  const onUploadProgress = (percent) => {
    setProgress(percent);
  };

  const onUploadError = (status) => {
    setError(`Upload error: ${status}`);
  };

  const onUploadFinish = (signResult) => {
    uploaderRef.current.clear();
    setUploading(false);
    setFileUrls((prevState) => [...prevState, signResult]);
  };

  const getSignedUrl = (file, callback) => {
    const params = {
      objectName: file.name,
      contentType: file.type,
    };

    attachmentService.presignUrl(params).then(callback).catch(console.error.bind(this));
  };

  const removeFile = (file, index) => {
    const newFiles = removeArrayElementAtIndex(files, index);
    const newFileUrls = removeArrayElementAtIndex(fileUrls, index);

    setFiles(newFiles);
    setFileUrls(newFileUrls);
  };

  const resetUploader = () => {
    setFiles([]);
    setFileUrls([]);
    setError();
  };

  return {
    files,
    fileUrls,
    uploading,
    progress,
    error,
    getSignedUrl,
    onUploadStart,
    onUploadProgress,
    onUploadFinish,
    onUploadError,
    removeFile,
    resetUploader,
  };
};

export default useFileUpload;
