import { useContext, useEffect } from 'react';

import Day from 'dayjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setImportRowCount } from 'app/routes/Import/state/actions';
import {
  selectNotificationsRefCurrent,
  selectPushNotifications,
} from 'app/state/push-notifications/selectors';
import useHotjar from 'hooks/useHotjar';
import { updateCounters } from 'state/counters/actions';
import { addNewPushNotification, removePushNotification } from 'state/push-notifications/reducer';

import { useNotificationsForRecordsCount } from './useNotificationsForRecordsCount';
import DateHelper from '../../../helpers/DateHelper';
import { PusherContext } from '../../contexts/PusherContext';
import { updateCurrentUser } from '../../state/user/actions';
import {
  resetStatePartially,
  setChatId,
} from '../CallDialog/MessagesTab/Conversation/state/reducer';
import {
  minimizeCallModal,
  setActiveTab,
  setConversationScreenOpen,
  setPhoneDialogOpen,
  setUpdatedVoicemailsIDS,
} from '../CallDialog/state/actions';
import { selectActiveTab, selectIsPhoneDialogOpen } from '../CallDialog/state/selectors';
import PushNotification from '../PushNotification';

function collaborationNoteData(data, type) {
  return {
    uid: new Day().format(),
    type,
    subject: data.message,
    noteID: data.collaboration_note_id,
    resourceType: data.resource_type,
    resourceID: data.resource_id,
    resourceName: data.resource_name,
    user: {
      fullName: data.subject.full_name,
      photoUrl: data.subject.photo_url,
    },
    kind: data.kind,
    customFieldID: data.custom_field_id,
    subSubject: `Today at ${DateHelper.getCurrentTime()}`,
  };
}

function bindToPusherChannel(channel, event, callback) {
  channel.bind(event, (data) => {
    callback(data);
  });
}

function PushNotifications({
  userId,
  setTwilioNumberVerificationStatus,
  storeAvailability,
  storeUnreadMessage,
  alertsDisabled,
  parentInteraction = null,
}) {
  const notifications = useSelector(selectPushNotifications);
  const notificationsRefCurrent = useSelector(selectNotificationsRefCurrent);
  const isPhoneDialogOpen = useSelector(selectIsPhoneDialogOpen);
  const phoneAppActiveTab = useSelector(selectActiveTab);
  const { pusher, channel } = useContext(PusherContext);
  const { logCustomEvent } = useHotjar();

  const assistantNotificationsChannel = pusher.subscribe(
    `spiro_channel_assistant_notifications_${userId}`
  );

  const dispatch = useDispatch();
  const { getActionByResourceName } = useNotificationsForRecordsCount();

  const setNotifications = (arr) => dispatch(addNewPushNotification(arr));

  const subscribeToPusher = () => {
    bindToPusherChannel(channel, 'inbound_sms', (message) => {
      const uid = new Day().format();

      if (!alertsDisabled) {
        setNotifications({
          uid,
          type: 'inbound_text',
          subject: message.subject,
          subSubject: `Today at ${DateHelper.getCurrentTime()}`,
          threadID: message.text_message_thread_id,
        });
      }
      const isTabInFocus = document.hasFocus();
      const permission = Notification.permission;

      if ('Notification' in window && !isTabInFocus && permission === 'granted') {
        const notification = new Notification(`New ${message.subject}`, {
          requireInteraction: true,
          tag: message.parent_id || message.id,
          body: message.body,
        });
        notification.onclick = () => {
          dispatch(removePushNotification(uid));
          notificationsRefCurrent.removeNotification({
            uid,
            type: 'inbound_text',
            text: message.subject,
            subject: message.body,
            threadID: message.text_message_thread_id,
          });
          window.focus();
          notification.close();
          dispatch(resetStatePartially());
          dispatch(setPhoneDialogOpen(true));
          dispatch(minimizeCallModal(false));
          dispatch(setChatId(message.text_message_thread_id));
          dispatch(setActiveTab('Messages'));
          dispatch(setConversationScreenOpen(true));
          logCustomEvent('phone_modal_opened');
        };
      }

      if (!parentInteraction) return;
      if (parentInteraction.id === message.parent_id) {
        storeUnreadMessage(message);
      }
    });

    bindToPusherChannel(channel, 'missed_call', (call) => {
      if (!alertsDisabled) {
        setNotifications({
          uid: new Day().format(),
          type: 'missed_call',
          subject: call.subject,
          subSubject: `Today at ${DateHelper.getCurrentTime()}`,
          isGroupCall: call.group_call,
        });
      }
    });

    bindToPusherChannel(channel, 'twilio_number_verification', (data) => {
      setTwilioNumberVerificationStatus(data);
    });

    bindToPusherChannel(channel, 'twilio_availability', (data) => {
      storeAvailability(data.status.available);
    });

    bindToPusherChannel(channel, 'external_notification', (data) => {
      if (!alertsDisabled) {
        setNotifications({
          uid: new Day().format(),
          type: 'external_notification',
          subject: data?.subject,
          subSubject: `Today at ${DateHelper.getCurrentTime()}`,
          link: data.link,
        });
      }
    });

    bindToPusherChannel(assistantNotificationsChannel, 'resources_added', (data) => {
      setNotifications({
        uid: new Day().format(),
        type: 'assistant_notification',
        subject: data?.subject,
        subSubject: data.message,
        ruleId: data.assistant_rule_id,
        isEnabledOnAssistantPage: data.enabled_on_assistant_tab,
        resourceType: data.record_type,
        resourceID: data.record_id,
      });
    });

    bindToPusherChannel(channel, 'reopened_ticket', (data) => {
      setNotifications({
        uid: new Day().format(),
        type: 'reopened_ticket',
        subject: data.subject,
        subSubject: `Today at ${DateHelper.getCurrentTime()}`,
        ticketID: data.ticket_id,
      });
    });

    bindToPusherChannel(channel, 'collaboration_note_mention', (data) => {
      setNotifications(collaborationNoteData(data, 'collaboration_note_mention'));
    });

    bindToPusherChannel(channel, 'collaboration_note_mention_reply', (data) => {
      setNotifications(collaborationNoteData(data, 'collaboration_note_mention_reply'));
    });

    bindToPusherChannel(channel, 'collaboration_note_created', (data) => {
      setNotifications(collaborationNoteData(data, 'collaboration_note_created'));
    });

    bindToPusherChannel(channel, 'has_unread_notifications_updated', (data) => {
      dispatch(updateCurrentUser(data));
    });

    channel.bind('missed_call_counter_updated', (data) => {
      dispatch(updateCounters({ missedCalls: data.missed_calls_count }));
    });

    channel.bind('unread_message_counter_updated', (data) => {
      dispatch(updateCounters({ unreadSMS: data.messages }));
    });

    channel.bind('missed_voicemails_counter_updated', (data) => {
      dispatch(updateCounters({ voicemails: data.voicemails }));
      if (isPhoneDialogOpen && phoneAppActiveTab === 'Voicemail') {
        dispatch(setUpdatedVoicemailsIDS(data.affected_ids));
      }
    });

    // bindToPusherChannel(channel, 'create_contact_from_email', (data) => {
    //   setNotifications({
    //     uid: new Day().format(),
    //     type: 'new_contact',
    //     subject: data.full_name,
    //     text: 'New contact added',
    //     contactID: data.contact_id,
    //   });
    // });
    bindToPusherChannel(channel, 'import_row_count', (data) => {
      dispatch(setImportRowCount(data.row_count));
    });

    bindToPusherChannel(channel, 'records_count', (data) => {
      const setRecordsCount = getActionByResourceName(data.resource);
      dispatch(setRecordsCount(data.records_count));
    });
  };

  bindToPusherChannel(channel, 'failed_sms_notification', (data) => {
    if (!alertsDisabled) {
      setNotifications({
        uid: new Day().format(),
        type: 'failed_sms_notification',
        subject: data.message,
        contactID: data.contact_id,
        subSubject: `Today at ${DateHelper.getCurrentTime()}`,
      });
    }
  });

  useEffect(() => {
    subscribeToPusher();
  }, []);

  return <PushNotification notifications={notifications} />;
}

PushNotifications.propTypes = {
  setTwilioNumberVerificationStatus: PropTypes.func.isRequired,
  storeAvailability: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  storeUnreadMessage: PropTypes.func.isRequired,
  alertsDisabled: PropTypes.bool.isRequired,
  parentInteraction: PropTypes.object,
};

export default PushNotifications;
