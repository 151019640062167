import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { nullMeta, parseMetaFromResponse } from 'app/helpers/meta';
import { groupItemsByDate } from 'app/helpers/utils';
import { clearAllNotifications, getNotifications } from 'services/notifications';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';

export default function useNotifications() {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [meta, setMeta] = useState(nullMeta);
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
  });
  const dispatch = useDispatch();

  const fetchNotifications = async (requestParams = {}) => {
    const newParams = { ...params, ...requestParams };

    try {
      setIsLoading(true);
      const res = await getNotifications(newParams);

      const newData =
        res.meta.current_page === 1 ? res.notifications : [...notifications, ...res.notifications];
      const uniqMessages = [...new Map(newData.map((item) => [item.id, item])).values()];
      setNotifications(uniqMessages);
      setParams(newParams);
      setMeta(parseMetaFromResponse(res.meta));
      setIsLoading(false);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
    }
  };

  const markAllNotificationsAsRead = () =>
    clearAllNotifications()
      .then((json) => Promise.resolve(json))
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(error(e)));
        return Promise.reject(err);
      });

  const displayAction = (notification) => {
    const { sender, notification_type: type, custom, text } = notification;

    if (type === 'reminder_created') return `${sender.name} assigned you a reminder for `;
    if (type === 'reminder_updated') return `${sender.name} updated a reminder for `;
    if (type === 'reminder_completed') return `${sender.name} completed a reminder for `;
    if (type === 'collaboration_reaction_like_created')
      return `${sender.name} reacted ${String.fromCodePoint(0x1f44d)} to your note for `;
    if (type === 'collaboration_reaction_tick_created')
      return `${sender.name} reacted ${String.fromCodePoint(0x2705)} to your note for `;
    if (type === 'collaboration_reaction_celebrate_created')
      return `${sender.name} reacted ${String.fromCodePoint(0x1f44f)} to your note for `;
    if (type === 'collaboration_reaction_think_created')
      return `${sender.name} reacted ${String.fromCodePoint(0x1f914)} to your note for `;
    if (type === 'collaboration_reaction_fire_created')
      return `${sender.name} reacted ${String.fromCodePoint(0x1f525)} to your note for `;
    if (type === 'assistant_rule_single_resource_added') return ` added to ${text}`;
    if (type === 'assistant_rule_multiple_resources_added') return 'New resources added to ';
    if (type === 'external_notification') return custom.subject;
    if (type === 'collaboration_note_mention_reply')
      return `${sender.name} replied to a note you were mentioned on for `;
    if (type === 'collaboration_note_created') return `${sender.name} created a new note on `;
    if (type === 'sms_delivery_failed') return `SMS not delivered to `;
    return `${sender.name} mentioned you in a note on `;
  };

  return {
    isLoading,
    notifications: groupItemsByDate(notifications),
    meta,
    params,
    fetchNotifications,
    displayAction,
    markAllNotificationsAsRead,
  };
}
