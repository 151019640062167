import Day from 'dayjs';
import PropTypes from 'prop-types';

import AttachmentField from 'lib/AttachmentField';
import CampaignSelect from 'lib/CampaignSelect';
import CountrySelect from 'lib/CountrySelect';
import DatePicker from 'lib/DatePicker';
import MultipleSelect from 'lib/MultipleSelect';
import PercentageField from 'lib/PercentageField';
import Phone from 'lib/Phone';
import Select from 'lib/Select';
import TextField from 'lib/TextField';
import UserCurrencyTextField from 'lib/UserCurrencyTextField';
import {
  AccountLookup,
  ContactLookup,
  CustomObjectLookup,
  OpportunityLookup,
  SupportDeskLookup,
  UserLookup,
} from 'ui/FormControls';

function getDatePickerValue(name, value) {
  return new Day(value);
}

function setDateValue(name, date) {
  return new Day(date).format('YYYY-MM-DD');
}

function InputRenderer({ field }) {
  const defaultInput = (
    <TextField
      label={field.label}
      name={field.name}
      placeholder={field.placeholder}
      value={field.value}
      disabled={field.disabled}
      onChange={field.onChange}
      InputProps={field.inputProps}
      autoFocus={field?.autoFocus || false}
      isCompact={field.isCompact || false}
      required={field.required || false}
    />
  );

  switch (field.type) {
    case 'AccountLookup':
      return (
        <AccountLookup
          name={field.name}
          label={field.label}
          value={field.value ? field.value.label : ''}
          disabled={field.disabled}
          onChange={(searchTerm) =>
            field.onChange({ target: { name: field.name, value: searchTerm } })
          }
          onSelect={(item) =>
            field.onChange({
              target: { value: { value: item.id, label: item.name }, name: field.name },
            })
          }
          onBlur={(searchTerm) => {
            if (field.onBlur) field.onBlur(searchTerm, field.name);
          }}
          allowNonExistingValue={field.allowNonExistingValue}
          compactLookup={!!field.compactLookup}
          autoFocus={field?.autoFocus || false}
          contextFilterId={field?.contextFilterId || null}
        />
      );

    case 'ContactLookup':
      return (
        <ContactLookup
          value={field.value ? field.value.label : ''}
          name={field.name}
          label={field.label}
          disabled={field.disabled}
          onSelect={(item) =>
            field.onChange({
              target: { value: { value: item.id, label: item.name }, name: field.name },
            })
          }
          onBlur={(searchTerm) => {
            if (field.onBlur) field.onBlur(searchTerm, field.name);
          }}
          onChange={(searchTerm) =>
            field.onChange({ target: { name: field.name, value: searchTerm } })
          }
          allowNonExistingValue={field.allowNonExistingValue}
          compactLookup={!!field.compactLookup}
          contextType={field.contextType}
          contextId={field.contextId}
          autoFocus={field?.autoFocus || false}
          contextFilterId={field?.contextFilterId || null}
        />
      );
    case 'OpportunityLookup':
      return (
        <OpportunityLookup
          value={field.value ? field.value.label : ''}
          name={field.name}
          label={field.label}
          disabled={field.disabled}
          onSelect={(item) =>
            field.onChange({
              target: { value: { value: item.id, label: item.name }, name: field.name },
            })
          }
          onBlur={(searchTerm) => {
            if (field.onBlur) field.onBlur(searchTerm, field.name);
          }}
          onChange={(searchTerm) =>
            field.onChange({ target: { name: field.name, value: searchTerm } })
          }
          allowNonExistingValue={field.allowNonExistingValue}
          compactLookup={!!field.compactLookup}
          autoFocus={field?.autoFocus || false}
          contextFilterId={field?.contextFilterId || null}
          contextType={field.contextType}
          contextId={field.contextId}
        />
      );
    case 'UserLookup':
      return (
        <UserLookup
          value={field.value ? field.value.label : ''}
          name={field.name}
          label={field.label}
          disabled={field.disabled}
          onSelect={(item) =>
            field.onChange({
              target: { value: { value: item.id, label: item.name }, name: field.name },
            })
          }
          onBlur={(searchTerm) => {
            if (field.onBlur) {
              field.onBlur(searchTerm, field.name);
            }
          }}
          onChange={(searchTerm) =>
            field.onChange({ target: { name: field.name, value: searchTerm } })
          }
          allowNonExistingValue={field.allowNonExistingValue}
          compactLookup={!!field.compactLookup}
          autoFocus={field?.autoFocus || false}
        />
      );
    case 'CustomObjectLookup':
      return (
        <CustomObjectLookup
          value={field.value ? field.value.label : ''}
          label={field.label}
          name={field.name}
          disabled={field.disabled}
          onSelect={(item) =>
            field.onChange({
              target: { value: { value: item.id, label: item.name }, name: field.name },
            })
          }
          onBlur={(searchTerm) => {
            if (field.onBlur) {
              field.onBlur(searchTerm, field.name);
            }
          }}
          onChange={(searchTerm) =>
            field.onChange({ target: { name: field.name, value: searchTerm } })
          }
          allowNonExistingValue={field.allowNonExistingValue}
          customFieldId={field?.customFieldId || null}
          pickerKey={field?.pickerKey || null}
          compactLookup={!!field.compactLookup}
          autoFocus={field?.autoFocus || false}
        />
      );
    case 'Currency':
      return (
        <UserCurrencyTextField
          // step={0.01}
          name={field.name}
          label={field.label}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          autoFocus={field?.autoFocus || false}
        />
      );
    case 'Percentage':
      return (
        <PercentageField
          label={field.label}
          name={field.name}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          autoFocus={field?.autoFocus || false}
        />
      );
    case 'Date':
    case 'DateTime':
      return (
        <DatePicker
          isClearable
          onClear={() => field.onChange({ target: { name: field.name, value: null } })}
          name={field.name}
          label={field.label}
          value={field.value ? getDatePickerValue(field.name, field.value) : null}
          disabled={field.disabled}
          onChange={(date) =>
            field.onChange({
              target: { name: field.name, value: setDateValue(field.name, date) },
            })
          }
        />
      );

    case 'Float':
    case 'RelativeDate':
      return (
        <TextField
          type="number"
          label={field.label}
          name={field.name}
          placeholder={field.placeholder}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          // step={0.01} for Float and step={1} for Integer
          autoFocus={field.type === 'RelativeDate' ? false : field?.autoFocus || false}
        />
      );
    case 'Integer':
      return (
        <TextField
          type="number"
          label={field.label}
          name={field.name}
          placeholder={field.placeholder}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          autoFocus={field?.autoFocus || false}
        />
      );
    case 'List':
      return (
        <Select
          name={field.name}
          label={field.label}
          value={field.value}
          options={field.options}
          disabled={field.disabled}
          onChange={field.onChange}
          removable={field.removable}
          loading={field.loading || false}
          autoFocus={field?.autoFocus || false}
          defaultOpen={field?.autoFocus || false}
          required={field.required || false}
        />
      );
    case 'MultipleSelect':
      return (
        <MultipleSelect
          name={field.name}
          label={field.label}
          value={field.value}
          options={field.options}
          disabled={field.disabled}
          onChange={field.onChange}
          autoFocus={field?.autoFocus || false}
          defaultOpen={field?.autoFocus || false}
        />
      );
    case 'Text':
      return (
        <TextField
          multiline
          label={field.label}
          name={field.name}
          placeholder={field.placeholder}
          value={field.value}
          disabled={field.disabled}
          onChange={field.onChange}
          maxRows={field.rowsMax}
          autoFocus={field?.autoFocus || false}
        />
      );
    case 'Country':
      return (
        <CountrySelect
          name={field.name}
          label={field.label}
          value={field.value}
          onChange={field.onChange}
          autoFocus={field?.autoFocus || false}
          defaultOpen={field?.autoFocus || false}
        />
      );
    case 'Attachment':
      return (
        <AttachmentField
          label={field.label}
          name={field.name}
          value={field.value || { label: '', value: '' }}
          title={field.label}
          editable
          onSubmit={(value) => {
            field.onChange({ target: { value, name: field.name } });
          }}
          autoFocus={field?.autoFocus || false}
        />
      );
    case 'SupportDeskLookup':
      return (
        <SupportDeskLookup
          value={field.value ? field.value.label : ''}
          name={field.name}
          label={field.label}
          disabled={field.disabled}
          onSelect={(item) =>
            field.onChange({
              target: { value: { value: item.id, label: item.name }, name: field.name },
            })
          }
          onBlur={(searchTerm) => {
            if (field.onBlur) {
              field.onBlur(searchTerm, field.name);
            }
          }}
          onChange={(searchTerm) => field.onChange(searchTerm, field.name)}
          allowNonExistingValue={field.allowNonExistingValue}
          compactLookup={!!field.compactLookup}
        />
      );
    case 'Phone':
      return (
        <Phone
          label={field.label}
          value={field.value}
          name={field.name}
          onChange={(number) => field.onChange({ target: { value: number, name: field.name } })}
          defaultCountry={field.defaultCountry}
          sx={{ '& .MuiPhoneNumber-flagButton': { width: 'min-content' } }}
          autoFocus={field?.autoFocus || false}
        />
      );
    default:
      if (field.name === 'recent_list') {
        return (
          <CampaignSelect
            onSelect={(item) =>
              field.onChange({
                target: { value: item.id, name: field.name },
              })
            }
          />
        );
      }
      return defaultInput;
  }
}

InputRenderer.propTypes = {
  field: PropTypes.object.isRequired,
};

export default InputRenderer;
