import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import PropTypes from 'prop-types';

import { ReactComponent as InboundText } from 'app/activities/ActivitiesDataGrid/inbound-text.svg';
import { ReactComponent as MissedCallIcon } from 'app/activities/ActivitiesDataGrid/missed-call.svg';
import UserAvatar from 'components/UserAvatar';
import { ReactComponent as ExternalNotifications } from 'images/alarm-bell.svg';
import { ReactComponent as SpiritoIcon } from 'images/icons/spirito-outlined-icon.svg';
import { ReactComponent as TicketsIcon } from 'images/tickets.svg';

import { collaborationNotesNotifications, getNoteNotificationText } from './helpers';

import styles from './NotificationBody.module.scss';

const notificationIcon = {
  missed_call: <MissedCallIcon data-cy="push-notification-missed-call-icon" />,
  inbound_text: <InboundText data-cy="push-notification-inbound-text-icon" />,
  external_notification: (
    <ExternalNotifications data-cy="push-notification-external-notification-icon" />
  ),
  assistant_notification: (
    <div className={styles['icon-wrapper']}>
      <SpiritoIcon data-cy="push-notification-assistant-icon" />
    </div>
  ),
  new_contact: (
    <div className={styles.icon}>
      <PersonOutlineIcon data-cy="push-notification-new-contact-icon" />
    </div>
  ),
  reopened_ticket: (
    <div className={styles.icon}>
      <TicketsIcon data-cy="push-notification-reopened-ticket-icon" />
    </div>
  ),
  failed_sms_notification: (
    <div className={styles.icon}>
      <SmsFailedOutlinedIcon data-cy="push-notification-reopened-failed-sms-icon" />
    </div>
  ),
};

export default function NotificationBody({ notification }) {
  const { type, subject, user, subSubject, isGroupCall } = notification;

  return (
    <div className={styles.container}>
      {!collaborationNotesNotifications.includes(type) && (
        <>
          {notificationIcon[type]}
          <div className={styles['text-container']}>
            <div className={`text-truncate ${styles.subject}`}>{subject}</div>
            <div className={`text-truncate ${styles['sub-subject']}`}>
              {isGroupCall && (
                <GroupsOutlinedIcon
                  fontSize="inherit"
                  data-cy="push-notification-group-call-icon"
                />
              )}
              {subSubject}
            </div>
          </div>
        </>
      )}
      {collaborationNotesNotifications.includes(type) && (
        <>
          <UserAvatar user={user} isSmall />
          <div className={styles['text-container']}>
            <div className={`text-truncate ${styles.subject}`}>
              {`${user.fullName} ${getNoteNotificationText(type)}`}
            </div>
            <div className={`text-truncate ${styles['sub-subject']}`}>{subSubject}</div>
          </div>
        </>
      )}
    </div>
  );
}

NotificationBody.propTypes = {
  notification: PropTypes.object.isRequired,
};
