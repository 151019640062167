import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import styles from './IconButtonWithLabel.module.scss';

export default function IconButtonWithLabel({
  handleOnClick,
  icon,
  color,
  label,
  classes,
  disabled,
  iconSize,
}) {
  return (
    <div className={`${classes} ${styles['button-wrapper']}`}>
      <div className={styles[`button-size-${iconSize}`]}>
        <IconButton
          classes={{ disabled: styles['disabled-button'] }}
          disabled={disabled}
          onClick={handleOnClick}
          className={styles[`button-${color}`]}
          size="large"
        >
          {icon}
        </IconButton>
      </div>
      {label && (
        <Typography className={disabled ? styles['label-disabled'] : styles[`label-${color}`]}>
          {label}
        </Typography>
      )}
    </div>
  );
}

IconButtonWithLabel.defaultProps = {
  classes: '',
  label: '',
  iconSize: 'large',
  disabled: false,
};

IconButtonWithLabel.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  icon: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
  classes: PropTypes.string,
  iconSize: PropTypes.string,
  disabled: PropTypes.bool,
};
