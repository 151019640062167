import { useState } from 'react';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

import styles from './ImageLink.module.scss';

export default function ImageLink({ url }) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setError('Spiro cannot support this file type in messages. To see the attachment, press ');
    setIsLoading(false);
  };

  if (error && !isLoading)
    return (
      <span className={styles.error}>
        <ErrorOutlineOutlinedIcon />
        <span>{error}</span>
        <a href={url} target="_blank" rel="noopener noreferrer" className={styles.link}>
          here
        </a>
      </span>
    );

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={styles['image-link']}>
      <Skeleton
        variant="rounded"
        width={230}
        height={230}
        sx={{ mb: '10px' }}
        className={isLoading ? styles.shown : styles.hidden}
      />
      <img
        src={url}
        alt="media url"
        onError={handleImageError}
        className={isLoading ? styles.hidden : styles.shown}
        onLoad={handleImageLoad}
      />
    </a>
  );
}

ImageLink.propTypes = {
  url: PropTypes.string.isRequired,
};
