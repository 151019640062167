import { useContext, useEffect, useState } from 'react';

import PhoneDisabledOutlinedIcon from '@mui/icons-material/PhoneDisabledOutlined';
import { useDispatch, useSelector } from 'react-redux';

import { parseMetaFromResponse } from 'helpers/meta';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { getVoicemails } from 'services/spiro-phone';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';
import { PusherContext } from '../../../contexts/PusherContext';
import NoAvailableRecords from '../NoAvailableRecords';
import { setUpdatedVoicemailsIDS } from '../state/actions';
import { selectUpdatedVoicemailsIDS } from '../state/selectors';
import VoicemailCard from '../VoicemailCard';

import styles from './Voicemail.module.scss';

export default function Voicemail() {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cardShouldBeClosed, setCardShouldBeClosed] = useState(false);
  const [selectedVoicemail, setSelectedVoicemail] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);
  const [createdMissedVoicemail, setCreatedMissedVoicemail] = useState();
  const updatedVoicemailsIDS = useSelector(selectUpdatedVoicemailsIDS);
  const { channel } = useContext(PusherContext);
  const dispatch = useDispatch();

  channel.bind('missed_voicemails_created', (missedVoicemail) => {
    setCreatedMissedVoicemail(missedVoicemail.object.missed_call_voicemail);
  });

  useEffect(() => {
    if (createdMissedVoicemail?.id) {
      setData([createdMissedVoicemail, ...data]);
    }
  }, [createdMissedVoicemail?.id]);

  useEffect(() => {
    if (updatedVoicemailsIDS.length) {
      const updatedData = data.map((voicemail) => {
        if (updatedVoicemailsIDS.includes(voicemail.id)) {
          return { ...voicemail, confirmed: true };
        }
        return voicemail;
      });
      setData(updatedData);
      dispatch(setUpdatedVoicemailsIDS([]));
    }
  }, [updatedVoicemailsIDS?.length]);

  const fetchData = async (params) => {
    try {
      const res = await getVoicemails(params);
      setData([...new Map([...data, ...res.voicemails].map((item) => [item.id, item])).values()]);
      setMeta(parseMetaFromResponse(res.meta));
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
    } finally {
      setLoading(false);
    }
  };

  const { handleRef } = useInfiniteScroll(loading, meta, fetchData);

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);

  const onBackdropClick = () => {
    setCardShouldBeClosed(true);
    setSelectedVoicemail(null);
    setTimeout(() => {
      setContentVisible(false);
      setCardShouldBeClosed(false);
    }, 300);
  };

  if (!loading && !data.length)
    return (
      <NoAvailableRecords
        title="Voicemail"
        message="No voicemails"
        icon={<PhoneDisabledOutlinedIcon fontSize="large" />}
      />
    );

  return (
    <>
      <div className={styles.header}>
        <h5>Voicemail</h5>
      </div>
      <div className={`${styles.body} ${selectedVoicemail ? styles['disable-scroll'] : ''}`}>
        {!!selectedVoicemail && (
          <div
            className={styles.backdrop}
            role="button"
            tabIndex="0"
            aria-label="backdrop"
            onClick={onBackdropClick}
          />
        )}
        {data.length > 0 &&
          data.map((voicemail, index) => (
            <VoicemailCard
              key={voicemail.id}
              ref={data.length - 1 === index ? handleRef : null}
              setSelectedVoicemail={setSelectedVoicemail}
              voicemail={voicemail}
              contentVisible={contentVisible}
              setContentVisible={setContentVisible}
              cardShouldBeClosed={cardShouldBeClosed}
            />
          ))}
      </div>
    </>
  );
}
