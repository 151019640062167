import { Autocomplete as MuiAutocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';

import { isScrolledToTheBottom } from 'helpers/utils';
import Checkbox from 'lib/Checkbox';
import TextField from 'lib/TextField';

import ListBox from './ListBox';
import { useAutocomplete } from './useAutocomplete';

import styles from './Autocomplete.module.scss';

export default function Autocomplete({
  meta,
  results,
  handleSearch,
  loading,
  value,
  onSelect,
  onFocus,
  onBlur,
  autoFocus,
  label,
  variant,
  classes,
  placeholder,
  resource,
  blurOnSelect,
  handleCheckboxChange,
  shouldDisplayExternalId,
  ...props
}) {
  const {
    setIsScrollBottom,
    setOpen,
    handleChange,
    handleSelect,
    open,
    searchTerm,
    options,
    onCheckboxChange,
    isChecked,
  } = useAutocomplete(meta, results, handleSearch, loading, value, onSelect, handleCheckboxChange);

  const displayName = (option) => {
    if (resource === 'account') return null;
    if (resource === 'contact') {
      return option.isCheckbox ? option.name : null;
    }
    return option.name;
  };
  const displayCheckboxOption = (option) => {
    if (meta?.contextAccountId && ['contact', 'opportunity', 'custom_objects'].includes(resource)) {
      return (
        <FormControlLabel
          control={<Checkbox checked={isChecked} name="checkboxOption" />}
          label={option.title}
          onMouseDown={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onCheckboxChange();
          }}
        />
      );
    }
    return null;
  };

  const displayAdditionalContactInfo = (option) => {
    if (option.email) {
      return (
        <span className={styles.wrapper}>
          {option.name}&nbsp;({option.email})
          {option.external_id && shouldDisplayExternalId && (
            <span className={styles['external-id']}>{option.external_id}</span>
          )}
        </span>
      );
    }
    return (
      <span className={styles.wrapper}>
        {option.name}&nbsp;({option.account_name})
        {option.external_id && shouldDisplayExternalId && (
          <span className={styles['external-id']}>{option.external_id}</span>
        )}
      </span>
    );
  };

  const displayAdditionalAccountInfo = (option) => (
    <>
      <span className={styles.wrapper}>
        {option.name}&nbsp;({option.id})
        {option.external_id && shouldDisplayExternalId && (
          <span className={styles['external-id']}>{option.external_id}</span>
        )}
      </span>
      <div className={styles['option-sub-text']}>
        {option.address && <span>{option.address}&nbsp;</span>}
        {option.city && <span>{option.city}&nbsp;</span>}
        {option.state && <span>{option.state}&nbsp;</span>}
        {option.zipcode && <span>{option.zipcode}&nbsp;</span>}
        {option.country && <span>{option.country}&nbsp;</span>}
      </div>
    </>
  );

  const checkboxOptionLabel = {
    contact: 'Contacts',
    opportunity: 'Opportunities',
  };

  const checkboxOption = {
    id: -1,
    title: `Show Company ${checkboxOptionLabel[resource] || 'Records'} Only`,
    isCheckbox: true,
    type: 'Checkbox',
    name: '',
  };

  const noRecordsOption = {
    id: 1,
    noRecords: true,
    title: `No Company ${resource === 'contact' ? 'Contacts' : 'Records'}`,
    name: '',
  };

  const filteredResultsOption = {
    id: 1,
    isDropdownInfo: true,
    title: 'Showing filtered results',
    name: '',
  };

  const renderOptions = () => {
    if (
      meta?.contextAccountId &&
      ['contact', 'custom_objects'].includes(resource) &&
      !options.length
    )
      return [noRecordsOption];

    if (props.contextFilterId) return [filteredResultsOption, ...options];

    return options;
  };

  return (
    <MuiAutocomplete
      {...props}
      disableClearable
      disableCloseOnSelect
      ListboxComponent={ListBox}
      ListboxProps={{
        checkboxOption: displayCheckboxOption(checkboxOption),
        onScroll: ({ target }) => setIsScrollBottom(isScrolledToTheBottom(target)),
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={(event, reason) => {
        if (reason !== 'selectOption') {
          setOpen(false);
        }
      }}
      inputValue={searchTerm}
      onFocus={() => onFocus(isChecked)}
      onBlur={() => onBlur(searchTerm)}
      getOptionLabel={(option) => option.name}
      disableListWrap
      renderOption={(inputProps, option) => {
        if (option.isDropdownInfo) {
          return (
            <li {...inputProps} className={styles['dropdown-info']}>
              {option.title}
            </li>
          );
        }
        return (
          <li {...inputProps} key={`${option.name}-${Math.random()}`}>
            <div className="full-width">
              {option.noRecords && <span>{option.title}</span>}
              {displayName(option)}
              {resource === 'contact' && !option.noRecords
                ? displayAdditionalContactInfo(option)
                : ''}
              {resource === 'account' && displayAdditionalAccountInfo(option)}
            </div>
          </li>
        );
      }}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      options={renderOptions()}
      filterOptions={(filterOptions) => filterOptions}
      onChange={(_, item) => handleSelect(item)}
      className={classes}
      blurOnSelect={blurOnSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => handleChange(e)}
          autoFocus={autoFocus}
          label={label}
          variant={variant}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

Autocomplete.defaultProps = {
  value: '',
  results: [],
  meta: {},
  variant: 'filled',
  autoFocus: false,
  classes: '',
  placeholder: '',
  blurOnSelect: true,
  shouldDisplayExternalId: false,
  contextFilterId: null,
};

Autocomplete.propTypes = {
  results: PropTypes.array,
  handleSearch: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  value: PropTypes.string,
  meta: PropTypes.object,
  variant: PropTypes.string,
  autoFocus: PropTypes.bool,
  label: PropTypes.string.isRequired,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  resource: PropTypes.string.isRequired,
  blurOnSelect: PropTypes.bool,
  handleCheckboxChange: PropTypes.func.isRequired,
  shouldDisplayExternalId: PropTypes.bool,
  contextFilterId: PropTypes.number,
};
