import {
  CLEAR_LISTS,
  HIDE_MODAL,
  SET_LISTS_REQUEST_STATUS,
  SHOW_MODAL,
  STORE_LISTS,
  STORE_SELECTED_LIST,
} from './constants';

const initialState = {
  visible: false,
  lists: [],
  requestStatus: 'idle',
  selectedList: '',
  contact: null,
};

function showModalHandler(state, action) {
  return {
    ...state,
    visible: true,
    contact: action.data ? action.data.contact : null,
  };
}

function hideModalHandler(state) {
  return {
    ...state,
    visible: false,
  };
}

function storeListsHandler(state, action) {
  return { ...state, lists: [...state.lists, ...action.data] };
}

function storeSelectedListHandler(state, action) {
  return { ...state, selectedList: action.data };
}

function setListsRequestStatusHandler(state, action) {
  return { ...state, requestStatus: action.status };
}

function clearListsHandler(state) {
  return {
    ...state,
    lists: [],
    requestStatus: 'idle',
    selectedList: '',
  };
}

const ACTION_HANDLERS = {
  [SHOW_MODAL]: showModalHandler,
  [HIDE_MODAL]: hideModalHandler,
  [SET_LISTS_REQUEST_STATUS]: setListsRequestStatusHandler,
  [STORE_LISTS]: storeListsHandler,
  [STORE_SELECTED_LIST]: storeSelectedListHandler,
  [CLEAR_LISTS]: clearListsHandler,
};

export default function listSelectModalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
