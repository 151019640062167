import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

import TextField from 'lib/TextField';

function ListSelect({ lists, handleSelect, loading = false, loadingText = 'Loading...' }) {
  return (
    <Autocomplete
      disableClearable
      options={lists}
      onChange={(_, item) => handleSelect(item)}
      getOptionLabel={(option) => option.title}
      renderOption={(inputProps, option) => (
        <li
          {...inputProps}
          key={`${option.id}-${Math.random()}`}
          style={{ backgroundColor: 'white' }}
        >
          <div>{option.title}</div>
        </li>
      )}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      loading={loading}
      loadingText={loadingText}
      renderInput={(params) => <TextField {...params} label="Campaigns" />}
    />
  );
}

ListSelect.propTypes = {
  lists: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};

export default ListSelect;
