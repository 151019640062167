import { useEffect } from 'react';

import Hotjar from '@hotjar/browser';
import { useSelector } from 'react-redux';

import { selectUser } from 'state/user/selectors';

export default function useHotjar() {
  const user = useSelector(selectUser);
  const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (isProduction && process.env.HOTJAR_SITE_ID && user && user.id) {
      Hotjar.identify(user.id, {
        full_name: user.fullName,
        email: user.email,
      });
    }
  }, [user?.id]);

  const logCustomEvent = (eventName) => {
    if (isProduction && process.env.HOTJAR_SITE_ID) {
      Hotjar.event(eventName);
    }
  };

  const logOpeningFromSideDrawer = () => {
    logCustomEvent('opened_details_from_drawer');
  };

  return { logCustomEvent, logOpeningFromSideDrawer };
}
