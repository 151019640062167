import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AddIcon from '@mui/icons-material/Add';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import IconButtonWithLabel from 'lib/IconButtonWithLabel';

import { selectIsIncomingCall } from '../../state/selectors';
import useCallActions from '../CallMainActions/useCallActions';

import styles from './CallActions.module.scss';

export default function CallActions({ color }) {
  const { setReminder, sendEmail, contact, caller, toggleNoteScreen } = useCallActions();
  const incomingCall = useSelector(selectIsIncomingCall);

  return (
    <Box className={styles.container}>
      <IconButtonWithLabel
        icon={<AccessAlarmsIcon />}
        classes={styles.button}
        color={color}
        handleOnClick={setReminder}
        label="Reminder"
        disabled={!caller}
      />
      <IconButtonWithLabel
        icon={<MailOutlineIcon />}
        classes={styles.button}
        color={color}
        handleOnClick={sendEmail}
        label="Send Email"
        disabled={!caller || !contact?.email}
      />
      <IconButtonWithLabel
        icon={<AddIcon />}
        classes={styles.button}
        color="primary"
        handleOnClick={toggleNoteScreen}
        label="Notes"
        disabled={incomingCall}
      />
    </Box>
  );
}

CallActions.defaultProps = {
  color: 'black',
};

CallActions.propTypes = {
  color: PropTypes.string,
};
