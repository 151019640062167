import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import CallerInfoImage from './CallerInfoImage';
import CallerInfoText from './CallerInfoText';
import CallDurationTimer from '../../CallDurationTimer';

import styles from './CallerInfo.module.scss';

export default function CallerInfo({ mainTextColor, subTextColor, fullInfo }) {
  return (
    <>
      <Box className={fullInfo ? styles['full-caller-info'] : styles['caller-info']}>
        <CallerInfoImage />
        <CallerInfoText
          fullInfo={fullInfo}
          mainTextColor={mainTextColor}
          subTextColor={subTextColor}
        />
      </Box>
      {!fullInfo && (
        <Box className={styles.timer}>
          <CallDurationTimer isLarge />
        </Box>
      )}
    </>
  );
}

CallerInfo.defaultProps = {
  mainTextColor: 'white',
  subTextColor: 'white',
  fullInfo: true,
};

CallerInfo.propTypes = {
  mainTextColor: PropTypes.string,
  subTextColor: PropTypes.string,
  fullInfo: PropTypes.bool,
};
