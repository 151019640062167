import { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useHotjar from 'hooks/useHotjar';

export default function useListSearch(
  fetchData,
  defaultValue = '',
  param = 'query',
  entity = null,
  clearResourceCount = null
) {
  const [searchQuery, setSearchQuery] = useState(defaultValue);
  const location = useLocation();
  const dispatch = useDispatch();
  const { logCustomEvent } = useHotjar();

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleSearchEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        fetchData({ [param]: searchQuery, page: 1 });
        logCustomEvent('list_search_results_fetched');
        if (clearResourceCount) dispatch(clearResourceCount(0));
      }
    },
    [searchQuery, entity]
  );

  const handleSearchClear = useCallback(async () => {
    setSearchQuery('');
    await fetchData({ [param]: '', page: 1 });
    if (clearResourceCount) dispatch(clearResourceCount(0));
  }, [location.pathname, entity]);

  return {
    searchQuery,
    handleSearchChange,
    handleSearchEnterPress,
    handleSearchClear,
  };
}
