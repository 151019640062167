import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Checkbox from 'lib/Checkbox';

const StyledFormControl = styled(FormControl)({
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent !important',
  },
});

const StyledMenuItem = styled(MenuItem)({
  '&.Mui-disabled': {
    opacity: 0.9,
    backgroundColor: '#ffffff',
  },
});

export default function MultipleSelect({
  name,
  className,
  label,
  value,
  options,
  fullWidth,
  onChange,
  ...props
}) {
  function renderValue(selectedValues) {
    return selectedValues
      .map((v) => {
        const option = options.find((o) => String(o.value) === String(v));
        return option?.label || '';
      })
      .join(', ');
  }

  function handleChange(e) {
    if (e.code === 'Enter') {
      props.onClose();
    } else {
      onChange({ target: { name, value: e.target.value.join(',') } });
    }
  }

  function isChecked(option) {
    const newVal = value.split(',');
    return newVal.includes(option);
  }

  const handleItemKeyDown = (optionValue, event) => {
    if (event.code === 'Space') {
      event.preventDefault();

      const newValues = value ? value.split(',') : [];
      const newValueSet = new Set(newValues);

      if (newValueSet.has(optionValue)) {
        newValueSet.delete(optionValue);
      } else {
        newValueSet.add(optionValue);
      }

      onChange({ target: { name, value: Array.from(newValueSet).filter(Boolean).join(',') } });
    }
  };

  return (
    <StyledFormControl className={className} variant="filled" fullWidth={fullWidth}>
      <InputLabel id="demo-mutiple-checkbox-label">{label}</InputLabel>
      <Select
        multiple
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        renderValue={renderValue}
        onChange={handleChange}
        value={value.length > 0 ? value.split(',') : []}
        {...props}
      >
        {options.map((option) => (
          <StyledMenuItem
            key={option.value}
            value={String(option.value)}
            disabled={option.disabled || false}
            onKeyDown={(event) => handleItemKeyDown(String(option.value), event)}
          >
            <Checkbox
              checked={isChecked(String(option.value))}
              disabled={option.disabled || false}
            />
            <ListItemText primary={option.label} />
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

MultipleSelect.defaultProps = {
  value: '',
  options: [],
  fullWidth: true,
  className: '',
};

MultipleSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  options: PropTypes.array,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
