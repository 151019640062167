import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import cs from 'classnames';
import PropTypes from 'prop-types';

import ImageLink from './ImageLink';
import SpiroLinkify from '../SpiroLinkify';

import styles from './ChatContent.module.scss';

export default function ChatItem({ text, position, mediaUrls, time, status, ...props }) {
  return (
    <div
      className={cs(styles.container, {
        [styles['content-left']]: position === 'left',
        [styles['content-right']]: position === 'right',
      })}
    >
      <div
        className={cs(styles.content, {
          [styles['content-contact']]: position === 'left',
          [styles['content-user']]: position === 'right',
          [styles['not-delivered']]: position === 'right' && status === 'failed',
        })}
      >
        <SpiroLinkify>
          <p>{text}</p>
        </SpiroLinkify>
        {mediaUrls.map((url) => (
          <ImageLink key={url} url={url} {...props} />
        ))}
        <div className={styles.chat__time}>
          <p
            className={
              position === 'left' ? styles['chat__time--left'] : styles['chat__time--right']
            }
          >
            {time}
          </p>
        </div>
      </div>
      {position === 'right' && status === 'failed' && (
        <div className={styles.warning}>
          <InfoOutlinedIcon fontSize="small" />
          <span>Not delivered</span>
        </div>
      )}
    </div>
  );
}

ChatItem.defaultProps = {
  mediaUrls: [],
  text: '',
  time: '',
  status: 'sent',
};

ChatItem.propTypes = {
  position: PropTypes.string.isRequired,
  text: PropTypes.string,
  mediaUrls: PropTypes.array,
  time: PropTypes.string,
  status: PropTypes.string,
};
