import { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import ListSelect from 'modals/ListSelectModal/ListSelect';
import { getTargetLists } from 'services/targets';

export default function CampaignSelect({ onSelect }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const ref = useRef([]);

  const getLists = (page = 1) => {
    getTargetLists({ page }).then((res) => {
      ref.current = [...ref.current, ...res.lists];
      if (res.meta.next_page) getLists(res.meta.next_page);
      else {
        setData(ref.current.filter((lists) => lists.status !== 'Inactive'));
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getLists();
  }, []);

  return <ListSelect lists={data} handleSelect={onSelect} loading={loading} />;
}

CampaignSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
};
