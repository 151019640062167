import Day from 'dayjs';

import {
  TWILIO_ACCEPT_CALL,
  TWILIO_CALL_MODAL_MINIMIZED,
  TWILIO_DISABLE_DEVICE,
  TWILIO_DISPLAY_DIALPAD,
  TWILIO_DISPLAY_SYSTEM_NOTIFICATION_FOR_CALL,
  TWILIO_DROP_CALL,
  TWILIO_ENABLE_DEVICE,
  TWILIO_HANG_UP_CALL,
  TWILIO_INIT_LOG_ACTIVITY_FLOW,
  TWILIO_MODAL_MINIMIZED_BY_OTHER_ACTION,
  TWILIO_MONITOR_CALL_DURATION,
  TWILIO_MUTE_CALL,
  TWILIO_REJECT_INCOMING_CALL,
  TWILIO_RESET_STATE,
  TWILIO_RESET_STATE_PARTIALLY,
  TWILIO_SET_ACCOUNT,
  TWILIO_SET_ACCOUNT_CALL_SHOULD_START,
  TWILIO_SET_ACTIVE_CALL_MODAL_OPEN,
  TWILIO_SET_ACTIVE_TAB,
  TWILIO_SET_CALL_INFO,
  TWILIO_SET_CALLER_INFO,
  TWILIO_SET_CONTACT,
  TWILIO_SET_CONVERSATION_SCREEN_OPEN,
  TWILIO_SET_FORM_VALUES,
  TWILIO_SET_NOTE_SCREEN_OPEN,
  TWILIO_SET_PHONE_DIALOG_OPEN,
  TWILIO_SET_PHONE_NUMBER,
  TWILIO_SET_RECORDING,
  TWILIO_SET_RINGING,
  TWILIO_SET_TICKET_ID,
  TWILIO_SET_TOKEN,
  TWILIO_SET_UPDATED_VOICEMAILS_IDS,
  TWILIO_SETUP_INCOMING_CALL,
  TWILIO_START_CALL,
  TWILIO_STORE_TWILIO_DEVICE,
  TWILIO_TOGGLE_ADD_CONTACT_MODAL,
  TWILIO_TOGGLE_ADD_EXISTING_CONTACT_MODAL,
  TWILIO_TOGGLE_TRANSFER_CALL,
} from './constants';

const initialState = {
  twilioEnabled: false,
  contact: null,
  account: null,
  caller: null,
  callers: [],
  twilioToken: '',
  outgoingCall: false,
  incomingCall: false,
  connection: null,
  callStatus: '',
  params: null,
  logInteraction: false,
  formValues: {
    callNotes: '',
    custom: {},
    disposition: '',
    subject: '',
  },
  makeACall: false,
  phoneNumber: null,
  callSid: null,
  accountCallShouldStart: false,
  device: null,
  isMuted: false,
  isActiveCallModalOpen: false,
  callDuration: 0,
  isTimerStarted: false,
  isModalMinimized: true,
  addContactModalVisible: false,
  addExistingContactModalVisible: false,
  isDialpadDisplayed: false,
  transferCallVisible: false,
  previousCall: {
    type: '',
    callSid: null,
    contact: null,
    account: null,
    caller: null,
    callers: [],
  },
  ringing: false,
  ticketId: null,
  recording: false,
  isPhoneDialogOpen: false,
  isNoteScreenOpen: false,
  isCallLogged: false,
  interactedAt: null,
  activeTab: localStorage.getItem('phoneActiveTab') || 'Messages',
  isConversationScreenOpen: false,
  modalMinimizedByOtherAction: false,
  displaySystemNotificationForCall: false,
  updatedVoicemailsIDS: [],
};

export default function callDialogReducer(state = initialState, action) {
  switch (action.type) {
    case TWILIO_ENABLE_DEVICE:
      return { ...state, twilioEnabled: true };
    case TWILIO_SET_TOKEN:
      return { ...state, twilioToken: action.token };
    case TWILIO_START_CALL:
      return {
        ...state,
        connection: action.connection,
        params: action.params,
        outgoingCall: true,
        incomingCall: false,
        makeACall: false,
        phoneNumber: action.params.phoneNumber,
        callSid: action.params.callSid,
        isTimerStarted: true,
        callDuration: 0,
      };
    case TWILIO_HANG_UP_CALL:
      return {
        ...state,
        connection: null,
        params: null,
        logInteraction: !!state.caller,
        makeACall: false,
        phoneNumber: action.connection.parameters.From || action.connection.message.phoneNumber,
        callSid: action.connection.parameters.CallSid,
        accountCallShouldStart: false,
        isDialpadDisplayed: false,
        isTimerStarted: false,
        isMuted: false,
        ringing: false,
        previousCall: {
          type: state.outgoingCall ? 'outbound_call' : 'inbound_call',
          callSid: action.connection.parameters.CallSid,
          contact: state.contact,
          account: state.account,
          caller: state.caller,
          callers: state.callers,
        },
        interactedAt: new Day().format('MMM. DD, YYYY	[at] HH:mm'),
        isNoteScreenOpen: true,
        isCallLogged: action.connection.sendHangup,
      };
    case TWILIO_SET_CALL_INFO:
      return {
        ...state,
        caller: action.caller,
        callers: action.callers,
        logInteraction: !!action.caller,
      };
    case TWILIO_SETUP_INCOMING_CALL:
      return {
        ...state,
        connection: action.connection,
        outgoingCall: false,
        incomingCall: true,
        callStatus: action.connection.status(),
        phoneNumber: action.connection.parameters.From,
        callSid: action.connection.parameters.CallSid,
        callDuration: 0,
        isModalMinimized: false,
        callers: [],
        caller: null,
      };
    case TWILIO_REJECT_INCOMING_CALL:
      return {
        ...state,
        ...initialState,
        caller: state.caller,
        phoneNumber: state.connection.parameters.From,
        twilioEnabled: state.twilioEnabled,
        isPhoneDialogOpen: true,
        isModalMinimized: false,
      };
    case TWILIO_ACCEPT_CALL:
      return {
        ...state,
        callStatus: action.connection.status(),
        isTimerStarted: true,
        incomingCall: false,
      };
    case TWILIO_DROP_CALL:
      return { ...state, ...initialState, twilioEnabled: state.twilioEnabled };
    case TWILIO_SET_FORM_VALUES:
      return { ...state, formValues: action.formValues };
    case TWILIO_INIT_LOG_ACTIVITY_FLOW:
      return { ...state, logInteraction: true };
    case TWILIO_SET_ACCOUNT_CALL_SHOULD_START:
      return {
        ...state,
        accountCallShouldStart: true,
        params: action.params,
      };
    case TWILIO_RESET_STATE:
      return {
        ...state,
        ...initialState,
        twilioEnabled: state.twilioEnabled,
        isModalMinimized: state.isModalMinimized,
        isPhoneDialogOpen: true,
        isCallLogged: false,
        activeTab: 'Recents',
      };
    case TWILIO_STORE_TWILIO_DEVICE:
      return { ...state, device: action.device };
    case TWILIO_DISABLE_DEVICE:
      return { ...state, twilioEnabled: false, isActiveCallModalOpen: false };
    case TWILIO_MUTE_CALL:
      return {
        ...state,
        isMuted: action.isMuted,
      };
    case TWILIO_SET_CALLER_INFO:
      return {
        ...state,
        caller: action.caller,
        logInteraction: !!action.caller,
      };
    case TWILIO_SET_ACTIVE_CALL_MODAL_OPEN:
      return { ...state, isActiveCallModalOpen: action.isActiveCallModalOpen };
    case TWILIO_SET_CONTACT:
      return {
        ...state,
        contact: action.contact,
        previousCall: state.previousCall.callSid
          ? { ...state.previousCall, contact: action.contact, caller: state.caller }
          : { ...state.previousCall },
      };
    case TWILIO_SET_ACCOUNT:
      return {
        ...state,
        account: action.account,
        previousCall: state.previousCall.callSid
          ? { ...state.previousCall, account: action.account, caller: state.caller }
          : { ...state.previousCall },
      };
    case TWILIO_MONITOR_CALL_DURATION:
      return { ...state, callDuration: state.callDuration + 1 };
    case TWILIO_TOGGLE_ADD_CONTACT_MODAL:
      return { ...state, addContactModalVisible: action.visible };
    case TWILIO_TOGGLE_ADD_EXISTING_CONTACT_MODAL:
      return { ...state, addExistingContactModalVisible: action.visible };
    case TWILIO_DISPLAY_DIALPAD:
      return { ...state, isDialpadDisplayed: action.isDialpadDisplayed };
    case TWILIO_TOGGLE_TRANSFER_CALL:
      return { ...state, transferCallVisible: action.transferCallVisible };
    case TWILIO_SET_PHONE_NUMBER:
      return { ...state, phoneNumber: action.phoneNumber };
    case TWILIO_RESET_STATE_PARTIALLY:
      return {
        ...initialState,
        twilioEnabled: state.twilioEnabled,
        callers: state.callers,
        caller: state.caller,
        twilioToken: state.twilioToken,
        outgoingCall: state.outgoingCall,
        incomingCall: state.incomingCall,
        connection: state.connection,
        callSid: state.callSid,
        logInteraction: false,
        phoneNumber: state.phoneNumber,
      };
    case TWILIO_SET_RINGING:
      return {
        ...state,
        ringing: action.ringing,
      };
    case TWILIO_SET_TICKET_ID: {
      return { ...state, ticketId: action.ticketId };
    }
    case TWILIO_SET_RECORDING: {
      return { ...state, recording: action.recording };
    }
    case TWILIO_SET_PHONE_DIALOG_OPEN: {
      return { ...state, isPhoneDialogOpen: action.isPhoneDialogOpen };
    }
    case TWILIO_SET_NOTE_SCREEN_OPEN: {
      return { ...state, isNoteScreenOpen: action.isNoteScreenOpen };
    }
    case TWILIO_SET_ACTIVE_TAB: {
      return { ...state, activeTab: action.activeTab };
    }
    case TWILIO_CALL_MODAL_MINIMIZED:
      return { ...state, isModalMinimized: action.isModalMinimized };
    case TWILIO_SET_CONVERSATION_SCREEN_OPEN:
      return {
        ...state,
        isConversationScreenOpen: action.isConversationScreenOpen,
      };
    case TWILIO_MODAL_MINIMIZED_BY_OTHER_ACTION:
      return { ...state, modalMinimizedByOtherAction: action.modalMinimizedByOtherAction };
    case TWILIO_DISPLAY_SYSTEM_NOTIFICATION_FOR_CALL:
      return { ...state, displaySystemNotificationForCall: action.display };
    case TWILIO_SET_UPDATED_VOICEMAILS_IDS:
      return { ...state, updatedVoicemailsIDS: action.updatedVoicemailsIDS };
    default:
      return state;
  }
}
