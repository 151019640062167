import { useDispatch, useSelector } from 'react-redux';
import { Device } from 'twilio-client';

import {
  minimizeCallModal,
  searchForContact,
  setActiveTab,
  setPhoneDialogOpen,
  setRinging,
  setTwilioCaller,
  setupTwilioDevice,
  startCall,
} from 'components/CallDialog/state/actions';
import { selectTwilioEnabled } from 'components/CallDialog/state/selectors';
import {
  mapCallerFromAccount,
  mapCallerFromContact,
  mapCallerFromTicket,
  removePhoneNumberFormatting,
} from 'helpers/twilio';
import useHotjar from 'hooks/useHotjar';

export default function useCall() {
  const dispatch = useDispatch();
  const twilioEnabled = useSelector(selectTwilioEnabled);
  const { logCustomEvent } = useHotjar();

  const makeCall = (phoneNumber, resource) => {
    const params = { phoneNumber };
    if (resource?.type === 'Contact')
      dispatch(setTwilioCaller(mapCallerFromContact(resource.value)));
    if (resource?.type === 'Ticket') dispatch(setTwilioCaller(mapCallerFromTicket(resource.value)));
    if (resource?.type === 'Account')
      dispatch(setTwilioCaller(mapCallerFromAccount(resource.value)));

    const connection = Device.connect(params);

    dispatch(startCall(connection, params));
    dispatch(setActiveTab('Phone'));

    connection.on('ringing', () => {
      dispatch(setRinging(true));
      dispatch(searchForContact(params.phoneNumber));
    });

    connection.on('accept', () => {
      dispatch(setRinging(false));
    });

    connection.on('reject', () => {
      dispatch(setRinging(false));
    });
  };

  const openPhoneDialog = () => {
    dispatch(setPhoneDialogOpen(true));
    dispatch(minimizeCallModal(false));
    logCustomEvent('phone_modal_opened');
  };

  function setupCall(phoneNumber, resource) {
    if (twilioEnabled) {
      makeCall(removePhoneNumberFormatting(phoneNumber), resource);
      openPhoneDialog();
    } else {
      dispatch(setupTwilioDevice()).then(() => {
        setTimeout(() => {
          openPhoneDialog();
          makeCall(removePhoneNumberFormatting(phoneNumber), resource);
        }, 1000);
      });
    }
  }

  return setupCall;
}
