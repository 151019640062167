import cs from 'classnames';
import PropTypes from 'prop-types';
import './BouncingDots.scss';

function BouncingDots({
  className = '',
  white = true,
  grey = false,
  black = false,
  darkGrey = false,
  sm = false,
  lg = false,
}) {
  return (
    <div
      className={cs('loader-bouncing-dots', className, {
        'loader-bouncing-dots--white': white,
        'loader-bouncing-dots--grey': grey,
        'loader-bouncing-dots--dark-grey': darkGrey,
        'loader-bouncing-dots--black': black,
        'loader-bouncing-dots--sm': sm,
        'loader-bouncing-dots--lg': lg,
      })}
      data-cy="bouncing-dots"
    >
      <div className="loader-bouncing-dots__child loader-bouncing-dots__child--bounce1" />
      <div className="loader-bouncing-dots__child loader-bouncing-dots__child--bounce2" />
      <div className="loader-bouncing-dots__child loader-bouncing-dots__child--bounce3" />
    </div>
  );
}

BouncingDots.propTypes = {
  className: PropTypes.string,
  white: PropTypes.bool,
  grey: PropTypes.bool,
  black: PropTypes.bool,
  darkGrey: PropTypes.bool,
  sm: PropTypes.bool,
  lg: PropTypes.bool,
};

export default BouncingDots;
