import { useEffect } from 'react';

import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Day from 'dayjs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { hideNotificationsSlider } from 'app/state/app-notifications/actions';
import Button from 'lib/Button';

import DateHeader from '../DateHeader';
import NotificationsAvatar from '../NotificationsAvatar';
import useNotifications from './useNotifications';

import styles from './Notifications.module.scss';

const assistantNotifications = [
  'assistant_rule_single_resource_added',
  'assistant_rule_multiple_resources_added',
];

const mentionsNotifications = [
  'collaboration_reaction_like_created',
  'collaboration_reaction_tick_created',
  'collaboration_reaction_celebrate_created',
  'collaboration_reaction_think_created',
  'collaboration_reaction_fire_created',
  'collaboration_mention_created',
  'collaboration_note_created',
];

function Notifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { notifications, meta, isLoading, fetchNotifications, displayAction } = useNotifications();

  const checkIfMentionsNotification = (value) => mentionsNotifications.includes(value);

  const handleClick = (type, id, customEntityId, kind, isMentionsNotification = false) => {
    const hash = isMentionsNotification ? '#collaboration' : '';

    dispatch(hideNotificationsSlider());
    navigate(
      {
        Account: `/companies/${id}${hash}`,
        Contact: `/contacts/${id}${hash}`,
        Opportunity: `/opportunities/${id}${hash}`,
        Quote: `/quotes/${id}${hash}`,
        Ticket: `/tickets/${id}${hash}`,
        CustomObject: `/lists/${customEntityId}/record/${id}${hash}`,
        Reminder: `/reminders?reminderID=${id}${hash}`,
        Activity: `/activities?activityID=${id}&kind=${kind}${hash}`,
        SalesOrder: `/orders/${id}${hash}`,
        List: `/campaigns/${id}${hash}`,
      }[type]
    );
  };

  const loadMoreNotifications = () => fetchNotifications({ page: meta.nextPage });

  useEffect(() => {
    fetchNotifications({ page: 1 });
  }, []);

  if (Object.keys(notifications).length === 0 && !isLoading) {
    return (
      <div className={styles['no-notifications']}>
        <NotificationsOffIcon />
        No Notifications
      </div>
    );
  }

  return (
    <div className={styles.notifications}>
      {Object.keys(notifications).map((group) => (
        <div key={uuidv4()}>
          <DateHeader date={group} />
          {notifications[group].map((n) => (
            <div key={n.id} className={styles.notification}>
              <NotificationsAvatar type={n.notification_type} sender={n.sender} />
              <div className={styles.info}>
                <div className={styles.heading}>
                  {n.resource.name &&
                    n.notification_type === 'assistant_rule_single_resource_added' && (
                      <span
                        role="button"
                        tabIndex="0"
                        key={n.id}
                        className={styles.link}
                        onClick={() => {
                          handleClick(
                            n.resource.type,
                            n.resource.id,
                            n.custom_field_id,
                            n.activity_type
                          );
                        }}
                      >
                        {n.resource.name}
                      </span>
                    )}
                  {displayAction(n)}
                  {n.notification_type === 'external_notification' && (
                    <span
                      role="button"
                      tabIndex="0"
                      key={n.id}
                      className={styles.link}
                      onClick={() => {
                        window.open(n.custom.link, '_blank');
                      }}
                    >
                      &nbsp;(See details)
                    </span>
                  )}
                  {n.notification_type === 'assistant_rule_multiple_resources_added' && (
                    <span
                      role="button"
                      tabIndex="0"
                      key={n.id}
                      className={styles.link}
                      onClick={() => {
                        if (n.assistant_rule.enabled_on_assistant_tab) {
                          navigate(`/assistant?assistant_rule_id=${n.assistant_rule.id}`);
                        }
                        dispatch(hideNotificationsSlider());
                      }}
                    >
                      {n.text}
                    </span>
                  )}
                  {n.notification_type === 'sms_delivery_failed' && (
                    <span
                      role="button"
                      tabIndex="0"
                      key={n.id}
                      className={styles.link}
                      onClick={() => {
                        handleClick(
                          n.resource.type,
                          n.resource.id,
                          n.custom_field_id,
                          n.activity_type,
                          false
                        );
                      }}
                    >
                      {n.resource.name} ({n.resource.phone})
                    </span>
                  )}
                  {n.resource.name &&
                    !assistantNotifications.includes(n.notification_type) &&
                    n.notification_type !== 'sms_delivery_failed' && (
                      <span
                        role="button"
                        tabIndex="0"
                        key={n.id}
                        className={styles.link}
                        onClick={() => {
                          handleClick(
                            n.resource.type,
                            n.resource.id,
                            n.custom_field_id,
                            n.activity_type,
                            checkIfMentionsNotification(n.notification_type)
                          );
                        }}
                      >
                        {n.resource.name}
                      </span>
                    )}
                </div>
                {(n.text || n.custom.message) &&
                  !assistantNotifications.includes(n.notification_type) && (
                    <div
                      className={styles.message}
                      dangerouslySetInnerHTML={{
                        __html:
                          n.text ||
                          n.custom.message
                            .replaceAll('<div', '<span')
                            .replaceAll('</div>', '</span>')
                            .replaceAll('<p', '<span')
                            .replaceAll('</p>', '</span>'),
                      }}
                    />
                  )}
              </div>
              <span className={styles.time}>{new Day(n.created_at).format('hh:mm A')}</span>
            </div>
          ))}
        </div>
      ))}
      {meta.nextPage && (
        <div className={styles.action}>
          <Button onClick={loadMoreNotifications}>See more</Button>
        </div>
      )}
    </div>
  );
}

export default Notifications;
