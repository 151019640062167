import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  hangUpCall,
  rejectIncomingCall,
  setActiveTab,
  setNoteScreenOpen,
  toggleTransferCall,
} from '../state/actions';
import { selectTwilioConnection } from '../state/selectors';

import styles from './RejectCall.module.scss';

function RejectCall({ iconSize = 'large', redirectToRecents = false }) {
  const dispatch = useDispatch();
  const connection = useSelector(selectTwilioConnection);

  const rejectCall = () => {
    if (connection.status() === 'pending') {
      connection.reject();
      dispatch(rejectIncomingCall());
      if (redirectToRecents) dispatch(setActiveTab('Recents'));
    } else {
      connection.disconnect((conn) => dispatch(hangUpCall(conn)));
      connection.disconnect();
      dispatch(toggleTransferCall(false));
      dispatch(setNoteScreenOpen(true));
    }
  };

  return (
    <Box className={styles.container}>
      <IconButton
        className={styles[`reject-call-${iconSize}`]}
        aria-label="Answer"
        onClick={rejectCall}
        size="large"
      >
        <PhoneOutlinedIcon className={styles.icon} />
      </IconButton>
    </Box>
  );
}

RejectCall.propTypes = {
  iconSize: PropTypes.string,
  redirectToRecents: PropTypes.bool,
};

export default RejectCall;
